import { useState } from "react";
import Call from "../icons/Call";
import Mail from "../icons/Mail";
import Home from "../icons/Home";
import Dots from "./Dots";
import Cake from "../icons/Cake";

export default function ProfileSection() {
  const [collapsed, setCollapsed] = useState(false);

  // useEffect(() => {
  //   const listener = () => {
  //     setCollapsed(window.scrollY > 0);
  //   };

  //   window.addEventListener('scroll', listener, { passive: true });

  //   return () => {
  //     window.removeEventListener('scroll', listener);
  //   }
  // }, []);

  return (
    <section className='profile__wrapper'>
      <div className="profile__head">
        <button
          className="clickable__wrapper"
          type='button'
          disabled={!collapsed}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <img className={['profile__image', collapsed && 'profile__image--collapsed'].filter(Boolean).join(' ')} src='/profile.jpg' alt='' />
        </button>

        <div className={['profile__name-wrapper', collapsed && 'profile__name-wrapper--collapsed'].filter(Boolean).join(' ')}>
          <h1 className='profile__title-name'>
            <span>Christian</span>Schindler
          </h1>
          <h4 className="profile__title-job-description">Softwareentwickler für Web- und Cloud-Anwendungen</h4>
        </div>
      </div>

      <div className='layout__two-columns'>
        <div className='flexbox__col'>
          <div className="profile__details">
            <section>
              <h2>Persönliches</h2>
              <div className="profile__info-row">
                <Cake />
                geb. am 07.05.1995 in Wesel
              </div>
              <div className="profile__info-row">
                <Home />
                Zum Schützenhof 11, 59821 Arnsberg
              </div>
              <div className="profile__info-row">
                <Call />
                <a href="tel:+491601128109" rel="noopener noreferrer" target="_blank">0160 1128109</a>
              </div>
              <div className="profile__info-row">
                <Mail />
                <a href="mailto:mail@chrisone.de" rel="noopener noreferrer" target="_blank">mail@chrisone.de</a>
              </div>
            </section>
          </div>

          <div className="profile__details">
            <section>
              <h2>Sprachen</h2>
              <div className="profile__info-row profile__info-row--no-icon">
                Deutsch (Muttersprache)
                <Dots count={5} />
              </div>
              <div className="profile__info-row profile__info-row--no-icon">
                Englisch (Wort und Schrift)
                <Dots count={4} />
              </div>
            </section>
          </div>

          <div className="profile__details">
            <section>
              <h2>Hobbys</h2>
              <div className="profile__info-row profile__info-row--no-icon">
                Gitarre und Schlagzeug spielen
              </div>
              <div className="profile__info-row profile__info-row--no-icon">
                Lautsprecherbau
              </div>
              <div className="profile__info-row profile__info-row--no-icon">
                Radsport
              </div>
            </section>
          </div>

          <div className="profile__details">
            <section>
              <h2>Soft Skills</h2>
              <div className="profile__info-row profile__info-row--no-icon">
                Teamgeist
              </div>
              <div className="profile__info-row profile__info-row--no-icon">
                Zuverlässigkeit
              </div>
              <div className="profile__info-row profile__info-row--no-icon">
                Genauigkeit
              </div>
              <div className="profile__info-row profile__info-row--no-icon">
                Eigeninitiative
              </div>
              <div className="profile__info-row profile__info-row--no-icon">
                Lernwille
              </div>
            </section>
          </div>
        </div>

        <div className='flexbox__col'>
          <div className="profile__details profile__details--tiny">
            <h2>Technologien</h2>

            <section>
              <h4 className="flex__row">Web Entwicklung <Dots count={5} /></h4>
              <div>
                Javascript, Typescript, HTML, CSS/SCSS, ReactJS, VueJS, Styled-Components, Webpack, Babel
              </div>
            </section>

            <section>
              <h4 className="flex__row">Server Entwicklung <Dots count={4} /></h4>
              <div>
                Datenbanken: MySQL, PostgreSQL, MongoDB, Redis
              </div>
              <div>
                NodeJS, Express, Sequelize, Mongoose / Typegoose, JWT, Elasticsearch, Stripe, NestJS, Strapi, Payload, Docker, Kubernetes, DigitalOcean, AWS (Amazon Web Services), Serverless
              </div>
            </section>

            <section>
              <h4 className="flex__row">Mobile Entwicklung <Dots count={4} /></h4>
              <div>
                Java, Kotlin, Gradle, Google Play Services, Firebase
              </div>
              <div>
                React-Native, GraphQL, Native Module Android/iOS, Unit- Tests mit Jest, Mocha und Chai
              </div>
            </section>

            <section>
              <h4 className="flex__row">Sonstige Platformen <Dots count={3} /></h4>
              <div>
                Desktop-Apps (Windows & Mac) mit Electron.js, Chromecast, FireTV, Android Auto, Bosch mySPIN, GM
              </div>
            </section>

            <section>
              <h4 className="flex__row">Software <Dots count={5} /></h4>
              <div>
                Webstorm, IntelliJ IDEA, Android Studio, Visual Studio Code, XCode, PgAdmin, MySQL Workbench, Robo 3T / Studio 3T, Postman, PAW, Sketch, Adobe XD, Atlassian Cloud (JIRA), Bitbucket, Github, Gitlab
              </div>
            </section>
          </div>
        </div>
      </div>

    </section>
  );
}