import { ReactNode } from "react";
import Location from "../icons/Location";

interface IProps {
  dateFrom?: string;
  dateTo?: string;

  title: string;
  location?: string;
  company?: string;

  tasks: (string | ReactNode)[];
}

export default function ExperienceEntry(props: IProps) {
  const {
    dateFrom,
    dateTo,

    title,
    location,
    company,

    tasks,
  } = props;

  return (
    <div className="content__item flex__row flex--align-start">
      <span className="content__time-range layout--desktop">
        {
          dateFrom
            ? <>{dateFrom} -<br/></>
            : dateTo ? 'bis ' : ''
        }
        {dateTo || ''}
      </span>
      <div className="decoration__line" />
      <div className="flex__col flex--gap-half">
        {
          dateTo &&
          <span className="content__time-range layout--mobile">
            {
              `${dateFrom ? `${dateFrom} - ` : dateTo ? 'bis ' : ''}${dateTo || ''}`
            }
          </span>
        }
        <div className="flex__row flex--gap-double">
          <h4>{title}</h4>
          {
            location &&
            <div className="flex__row flex--gap-quarter layout--desktop">
              <Location />
              {location}
            </div>
          }
        </div>
        {
          company &&
          <p>{[company, location].filter(Boolean).join(', ')}</p>
        }
        <ul className="list">
          {
            tasks.map((task, index) => (
              <li key={index}>{task}</li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}