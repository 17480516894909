interface IProps {
  count: number;
}

export default function Dots(props: IProps) {
  const {
    count,
  } = props;

  return (
    <div className="profile__dots-wrapper">
      {
        [...Array(5)].map((_, index) => (
          <div className={['decoration__dot', count > index && 'decoration__dot--active'].filter(Boolean).join(' ')} />
        ))
      }
    </div>
  )
}