import React from 'react';
import ExperienceSection from './components/ExperienceSection';
import ExperienceEntry from './components/ExperienceEntry';
import ProfileSection from './components/ProfileSection';

function App() {
  return (
    <div className="content__wrapper layout--appear">
      <div className="content__root">
        <div className="decoration__box decoration__box--top decoration__box--left decoration__box--right layout--screen-only" />

        <ProfileSection />

        <ExperienceSection title='Berufserfahrung' className='layout--print-break-before'>
          <ExperienceEntry
            dateFrom='04/2021'
            dateTo='aktuell'
            title='Senior Software Developer'
            location='Dortmund'
            company='brandneo GmbH'
            tasks={[
              'Auf- und Ausbau der Entwicklungsabteilung',
              'Planung, Umsetzung sowie Koordination von Web-Projekten als SPAs oder SSG/SSR Anwendungen',
              'Einstellen und Anlernen von neuen Mitarbeitern',
              'Ansprechpartner für Entwickler und verantwortlich für die Ausbildung von Fachinformatikern (Anwendungsentwicklung)',
            ]}
          />
          <ExperienceEntry
            dateFrom='11/2020'
            dateTo='03/2021'
            title='Senior Software Developer'
            location='Dortmund'
            company='Adesso SE'
            tasks={[
              'Entwicklung von Single-Page-Applications (SPAs) mit Vue und React',
              'Entwicklung von Frontend-Prototypes mit Storybook und Patternlab zur Integration mit FirstSpirit',
              'Agiles Arbeiten nach SCRUM in Projektteams mit bis zu 50 Personen',
              'Ansprechpartner für Entwickler',
            ]}
          />
          <ExperienceEntry
            dateFrom='10/2019'
            dateTo='10/2020'
            title='Frontend Developer (Web)'
            location='Dortmund'
            company='ARITHNEA GmbH'
            tasks={[
              'Entwicklung von Single-Page-Applications (SPAs) mit Vue und React',
              'Entwicklung von Frontend-Prototypes mit Storybook und Patternlab zur Integration mit FirstSpirit',
              'Agiles Arbeiten nach SCRUM in Projektteams mit bis zu 50 Personen',
            ]}
          />
          <ExperienceEntry
            dateFrom='09/2015'
            dateTo='09/2019'
            title='Android/Web Entwickler'
            location='Brilon/Dortmund'
            company='petesso GmbH'
            tasks={[
              'Programmierung von nativen Android-Apps mit Java',
              'Programmierung von hybrid Apps mit React-Native und Typescript',
              'Entwicklung von Admin-Dashboards im Web mit React und Typescript',
              'Entwicklung von API-Services mit Node JS und der AWS Cloud',
            ]}
          />
        </ExperienceSection>

        <ExperienceSection title='Weiterbildungen' className='layout--print-break-before'>
          <ExperienceEntry
            dateFrom='04/2022'
            dateTo='07/2022'
            title='AEVO Prüfung'
            location='Arnsberg'
            company='IHK Arnsberg-Hellweg-Sauerland'
            tasks={[
              'Ausbilder-Eignungs-Prüfung nach AEVO-Standard',
              'Voraussetzung um Fachinformatiker auszubilden',
            ]}
          />
          <ExperienceEntry
            dateFrom='02/2021'
            dateTo='07/2021'
            title='Externenprüfung IHK'
            location='Arnsberg'
            company='IHK Arnsberg-Hellweg-Sauerland'
            tasks={[
              'Externenprüfung zum Fachinformatiker Anwendungsentwicklung',
              'Anerkennung des Ausbildungsabschlusses aufgrund von Berufserfahrung',
            ]}
          />
        </ExperienceSection>

        <ExperienceSection title='Bildungsweg'>
          <ExperienceEntry
            dateFrom='10/2018'
            dateTo='09/2019'
            title='Studium Informatik'
            location='Düsseldorf'
            company='Heinrich-Heine-Universität'
            tasks={[
              'berufsbegleitendes Hochschulstudium',
              'Abgang nach 2 Semestern',
            ]}
          />
          <ExperienceEntry
            dateFrom='08/2016'
            dateTo='07/2018'
            title='Abendgymnasium'
            location='Arnsberg'
            company='Sauerland-Kolleg'
            tasks={[
              'berufsbegleitendes Abitur im Modell "Abi-Online"',
              'Abgang mit der allgemeinen Hochschulreife (Notendurchschnitt 1,9)',
            ]}
          />
          <ExperienceEntry
            dateFrom='09/2014'
            dateTo='08/2015'
            title='Bundesfreiwilligendienst (BufDi)'
            location='Arnsberg'
            company='SGV Jugendhof'
            tasks={[
              'freiwilliges soziales Jahr im Rahmen des Bundesfreiwilligendienstes',
              'Unterstützung in sämtlichen Bereichen in einer Jugendherberge',
            ]}
          />
          <ExperienceEntry
            dateFrom='08/2012'
            dateTo='07/2014'
            title='Berufskolleg (ITA)'
            location='Olsberg'
            company='Berufskolleg Olsberg'
            tasks={[
              'schulische Ausbildung zum Informationstechnischen Assistenten',
              'Abgang nach 2 Jahren zugunsten eines freiwilligen sozialen Jahres',
            ]}
          />
          <ExperienceEntry
            dateTo='07/2012'
            title='Gymnasium'
            location='Arnsberg'
            company='Gymnasium Laurentianum'
            tasks={[
              'Abgang nach der 12. Klasse mit Fachhochschulreife (einschl. nur theoret. Teil)',
            ]}
          />
        </ExperienceSection>

        <ExperienceSection title='Projekte bei brandneo GmbH' className='layout--print-break-before'>
          <ExperienceEntry
            title='Sonnenschein Personenbeförderung eFDL'
            company='Planungstool für die Fahrdienstleitung eines Personenbeförderungsunternehmen'
            tasks={[
              'Bedarfsanalyse und Konzeption der Planungssoftware',
              'Architektur der Software-Komponenten',
              'Entwicklungsleitung und Umsetzung von Frontend-App, API Service und Such-Engine'
            ]}
          />
          <ExperienceEntry
            title='Kickz M3ta'
            company='NFT Online Shop mit Loyaltiy System'
            tasks={[
              'Entwicklung Landingpage',
              'Entwicklung API Service und Shop Integration',
            ]}
          />
          <ExperienceEntry
            title='MOPO Paywall Middleware'
            company='Paywall SPA für die Hamburger Morgenpost'
            tasks={[
              'Entwicklung einer SPA zur Integration einer Paywall (inkl. Account Dashboard)',
              'Entwicklung eines API Services zur Synchronisation von Payment Provider und Paywall Platform',
            ]}
          />
          <ExperienceEntry
            title='Agentur Boomer'
            company='Relaunch interner Website'
            tasks={[
              'Entwicklung SSR App und CMS Anbindung',
            ]}
          />
          <ExperienceEntry
            title='Boomerjobs'
            company='Jobportal von Agentur Boomer'
            tasks={[
              'Entwicklung API Service und Zahlungsabwicklung',
              'Entwicklung Frontend und Suche',
            ]}
          />
          <ExperienceEntry
            title='Vivamo'
            company='Website Relaunch'
            tasks={[
              'Entwicklung SSG Website mit CMS',
            ]}
          />
          <ExperienceEntry
            title='Lösekes Steingarten'
            company='Website Relaunch'
            tasks={[
              'Entwicklung SSG Website mit CMS',
            ]}
          />
        </ExperienceSection>

        <ExperienceSection title='Projekte bei ARITHNEA GmbH und Adesso SE' className='layout--print-break-before'>
          <ExperienceEntry
            title='Digitaler Produktkatalog der DEW21'
            company='Desktop App für Vertriebsmitarbeiter zur Unterstützung im Beratungsgespräch'
            tasks={[
              'Entwicklung der Desktop App mit Electron.js',
              'Umsetzung des Offline Sync (offline first Prinzip)',
              'Umsetzung API und Admin-Dashboard',
            ]}
          />
          <ExperienceEntry
            title='Busch Vacuum Systems'
            company='Website Relaunch'
            tasks={[
              'Frontend Entwicklung der HTML und CSS Templates',
              'Entwicklung der SPA für den Product-Finder und',
              'Entwicklung der SPA für die Suche',
            ]}
          />
          <ExperienceEntry
            title='IQWiG (Institut für Qualität und Wirtschaftlichkeit im Gesundheitswesen) und Gesundheitsinformationen.de'
            company='Website Relaunch'
            tasks={[
              'Frontend Entwicklung der HTML und CSS Templates',
              'Entwicklung der SPA für die Suche',
            ]}
          />
          <ExperienceEntry
            title='Mitsubishi Electric'
            company='Website Relaunch'
            tasks={[
              'Frontend Entwicklung der HTML und CSS Templates',
            ]}
          />
          <ExperienceEntry
            title='Andsafe'
            company='Neuaufbau der Marke und Entwicklung einer dynamischen Antragsstrecke für eine Online-Versicherung'
            tasks={[
              'Entwicklung des Frontends als SPA mit Vue.js',
            ]}
          />
        </ExperienceSection>

        <ExperienceSection title='Projekte bei petesso GmbH' className='layout--print-break-before'>
          <ExperienceEntry
            title='DASH RADIO'
            company='Internetradio App'
            tasks={[
              'Android Entwicklung',
              'Chromecast App',
              'Fire-TV App',
              'Android Auto integration',
              'Bosch mySPIN App',
              'API Dashboard',
              'API Service',
            ]}
          />
          <ExperienceEntry
            title='SIKU (SikuControl)'
            company='App zur Fernsteuerung und Kalibrierung von Spielzeug-Traktoren (per Bluetooth)'
            tasks={[
              'Android Entwicklung',
              'Android Entwicklung der betriebsinternen Service-App',
              'Projektleitung und Kundenkommunikation',
            ]}
          />
          <ExperienceEntry
            title='joinr'
            company='App zum Vernetzen von Fahrern in einer Kolonne (wird u.A. beim Lions-Run benutzt)'
            tasks={[
              'Android Entwicklung',
              'API Service Entwicklung',
            ]}
          />
          <ExperienceEntry
            title='ModFind'
            company='Handelsplatform für Autoteile'
            tasks={[
              'React-Entwicklung (internes API Dashboard)',
            ]}
          />
          <ExperienceEntry
            title='BPM Supreme'
            company='DJ-Record-Pool App'
            tasks={[
              'React-Native Entwicklung',
            ]}
          />
          <ExperienceEntry
            title='SuperPhone'
            company='Platform für Kommunikation und Kontaktverwaltung'
            tasks={[
              'React-Native Entwicklung',
            ]}
          />
          <ExperienceEntry
            title='Manestreem'
            company='Platform um Dienstleistungen Anzubieten (z.B. Friseur mit Hausbesuch)'
            tasks={[
              'Android Entwicklung',
            ]}
          />
          <ExperienceEntry
            title='SL Messenger'
            company='Messenger App'
            tasks={[
              'Android Entwicklung',
            ]}
          />
          <ExperienceEntry
            title='LiVideo'
            company='Video-Broadcast Platform'
            tasks={[
              'Android Entwicklung',
            ]}
          />
        </ExperienceSection>

        <div className="decoration__box decoration__box--bottom decoration__box--left decoration__box--right layout--screen-only" />
      </div>
    </div>
  );
}

export default App;
