import { PropsWithChildren, ReactNode } from "react";

interface IProps {
  title: string | ReactNode;
  className?: string;
}

export default function ExperienceSection(props: PropsWithChildren<IProps>) {
  const {
    title,
    className,
    children,
  } = props;

  return (
    <section className={["content__section", className].filter(Boolean).join(' ')}>
      <h2 className="flex__row">
        <div className="decoration__box decoration__box--left" />
        {title}
      </h2>
      {children}
    </section>
  );
}